const _ = require('lodash');

const UNITED_SATES = [
  'Eccount Money',
  'Chase Bank',
  'Bank of America',
  'Wells Fargo',
  'U.S. Bank',
  'BBVA Compass',
  'Capital One Bank',
  'Bank of the west',
  'Santander Consumer Bank',
  'Citi Bank',
  'Huntington Bank',
  'M&T Bank',
  'Woodforest National Bank',
  'Citizens Bank',
  'Fifth Third Bank',
  'Key Bank',
  'TD Bank',
  'Sun Trust Bank',
  'Regions Bank',
  'PNC Bank',
  'BB&T Bank',
  'First National Bank',
  'BMO Harris Bank',
  'First Citizens Bank',
  'Comerica Bank',
  "People's United Bank",
  'Umpqua Bank',
  'Bank of the Ozarks',
  'HSBC',
  'MUFG Union Bank',
  'Arvest Bank',
  'Chemical Bank',
  'TCF Bank',
  'Synovus Bank',
  'Bancorp South Bank',
  'Washington Federal',
  'Assiciated Bank',
  'Iberiabank',
  'Valley National Bank',
  'Whitney Bank',
  'Trust Mark National Bank',
  'Great Western Bank',
  'Columbia State Bank',
  'Centennial Bank',
  'Old National Bank',
  'South State Bank',
  'First Tennessee Bank',
  'NBT Bank',
  'Renasant Bank',
  'Banner Bank',
  'Webster Bank',
  'Simmons Bank',
  'United Bank',
  'Frost Bank',
  'WesBanco Bank',
  'Commerce Bank',
  'Investors Bank',
  'TrustCo Bank',
  'First Commonwealth Bank',
  'Sterling National Bank',
  'Carter Bank And Trust',
  'First Midwest Bank',
  'First Bank',
  'Park National Bank',
  'Pinnacle Bank',
  'Glacier Bank',
  'Fulton Bank',
  'Rabobank',
  'Zions Bank',
  'First Merchants Bank',
  'East West Bank',
  'First Interstate Bank',
  'Union Bank and Trust',
  'Great Southern Bank',
  'Flagster Bank',
  'JP Morgan Chase Bank',
  'Bank of America',
  'Wells Fargo',
  'Citi Bank',
  'Goldman Sachs',
  'Morgan Stanely',
  'BNP Paribas',
  'BBVA',
  'Bank of New York',
  'Bank of Montreal',
  'Bank of Southside Virginia',
  'Clear Mountain Bank',
  'Community Bank Oregon',
  'Blue Harbor Bank',
  'Austin Capital Bank',
  'Beneficial State Bank',
  'Community Bank N.A',
  'Cross River Bank',
  'Fairfield County Bank',
  'FNB Bank',
  'First Community Bank of Central Alabama',
  'First Northern Bank',
  'Home Town Bank',
  'Patriot Bank',
  'United Community Bank',
  'JP Morgan Chase Bank',
  'Bank of America',
  'Wells Fargo',
  'Citi Bank',
  'Goldman Sachs',
  'Morgan Stanely',
  'U.S Bankcorp',
  'TD Bank',
  'HSBC',
  'American Express',
  'Goldman Sachs',
  'Morgan Stanely',
  'Bank of America (ML)',
  'Citi Group',
  'Wells Fargo Securities',
  'Barclays Investment Bank',
  'HSBC',
  'BNP Paribas',
  'UBS',
  'Deutsche Bank',
  'JP Morgan Chase Bank',
  'Bank of America ',
  'Wells Fargo',
  'Citigroup',
  'Goldman Sachs ',
  'Morgan Stanely',
  'U.S. Bankcorp',
  'PNC Financial Services',
  'TD Bank',
  'Capital One Bank',
  'HSBC',
  'Bank of the West',
  'State Street Corporation',
  'Sun Trust Bank',
  'American Express',
  'Ally Financial',
  'Barclays',
  'USAA',
  'Citizen Financial Group',
  'Fifth Third Bank',
  'Ally Bank',
  'CIT Bank',
  'Synchrony Bank',
  'Discover Bank',
  'FNBO Direct',
  'Bank5connect',
  'Barclays',
  'Ever BAnk',
  'GE Capital Bank',
  'Sallie Mae Bank',
  'TIAA Direct',
  'First Internet Bank',
  'Simple Bank',
  'Bank of internet',
  'Alliant Credit Union',
  'Radius Bank',
  'Chime Bank',
  'USAA',
  'Fidelity',
  'Charles Schwab Bank',
  'Sun Trust Bank',
  'Comerica',
  'PNC',
  'Arvest Bank',
  'Associated Bank',
  'Zions Bank',
  'Banner Bank',
  'Old National Bank',
  'Union Bank',
  'National Penn Bank',
  'MB Financial Bank',
  'Prosperity Bank',
  'TCF Bank',
  'UMB Bank',
  "People's United Bank",
  'Eastern Bank',
  'Key Corp',
  'Northwest Bank',
  'One West Bank',
  'First Republic',
]
// UK BANKS
const UNITED_KINGDOM = [
  "ABC International Bank PLC",
  "Access Bank UK LTD",
  "The Advanced Payment Solutions LTD",
  "Ahli United Bank(UK) PLC",
  "AIB Group(UK) PLC",
  "Al Rayan Bank PLC",
  "Aldermore Bank PLC",
  "Allica Bank LTD",
  "Alpha Bank London LTD",
  "Arbuthnot Latham & Co LTD",
  "ASHMAN BANK LTD",
  "Atom Bank PLC",
  "Axis Bank UK LTD",
  "Bank Leumi(UK) PLC",
  "Bank Mandiri(Europe) LTD",
  "Bank of Africa United Kingdom PLC",
  "Bank Of Baroda(UK) LTD",
  "Bank of Beirut(UK) LTD",
  "Bank of England",
  "Bank of Ceylon(UK) LTD",
  "Bank of China(UK) LTD",
  "Bank of Ireland(UK) PLC",
  "Bank of London and The Middle East PLC",
  "Bank of London Group LTD",
  "Bank of New York Mellon(International) LTD",
  "The Bank of Scotland PLC",
  "Bank of the Philippine Islands(Europe) PLC",
  "Bank North LTD",
  "Bank Saderat PLC",
  "Bank Sepah International PLC",
  "Barclays Bank PLC",
  "Barclays Bank UK PLC",
  "Birmingham Bank LTD",
  "British Arab Commercial Bank PLC",
  "Brown Shipley & Co LTD",
  "C Hoare & Co",
  "CAF Bank LTD",
  "Cambridge & Counties Bank LTD",
  "Castle Trust Capital PLC",
  "Cater Allen LTD",
  "Charity Bank LTD",
  "The Charter Court Financial Services LTD",
  "Chetwood Financial LTD",
  "Citibank UK LTD",
  "ClearBank LTD",
  "Close Brothers LTD",
  "Clydesdale Bank PLC",
  "Co - operative Bank PLC",
  "The Coutts & Company",
  "Credit Suisse(UK) LTD",
  "Credit Suisse International",
  "Crown Agents Bank LTD",
  "Cynergy Bank LTD",
  "DB UK Bank LTD",
  "DF Capital Bank LTD",
  "EFG Private Bank LTD",
  "Europe Arab Bank plc",
  'European Central Bank',
  "FBN Bank(UK) LTD",
  "FCE Bank PLC",
  "FCMB Bank(UK) LTD",
  "Finu 2 LTD",
  "Gatehouse Bank PLC",
  "GB Bank LTD",
  "Ghana International Bank PLC",
  "Goldman Sachs International Bank",
  "Guaranty Trust Bank(UK) LTD",
  "Gulf International Bank(UK) LTD",
  "Habib Bank Zurich PLC",
  "Hampden & Co PLC",
  "Hampshire Trust Bank PLC",
  "Handelsbanken PLC",
  "Havin Bank LTD",
  "HBL Bank UK LTD",
  "HM Treasury",
  "HSBC Bank PLC",
  "HSBC Private Bank(UK) LTD",
  "HSBC Trust Company(UK) LTD",
  "HSBC UK Bank PLC",
  "ICBC(London) plc",
  "ICBC Standard Bank PLC",
  "ICICI Bank UK PLC",
  "IFAST Global Bank LTD",
  "Investec Bank PLC",
  "Itau BBA International PLC",
  "IN Bank UK LTD",
  "J.P.Morgan Europe LTD",
  "J.P.Morgan Securities PLC",
  "Jordan International Bank PLC",
  "Julian Hodge Bank LTD",
  "Kexim Bank(UK) LTD",
  "Kingdom Bank LTD",
  "Kroo LTD",
  "Lloyds Bank PLC",
  "Lloyds Bank Corporate Markets PLC",
  "Natwest Bank",
  "Raiffeisenbank Oberes Lavanttal",
  "REVOLUT LTD UK",
  "Starling Bank"
];

const GENERAL = [
  'WISE',
  'Eccount Money',
  'Dubai Islamic Bank',
  'GIBRALTAR INTERNATIONAL BANK LIMITED',
  'JSC Bank Centercredit',
  'REVOLUT BANK',
  'REVOLUT BANK UAB',
  'UAB NETHERLAND',
  'ABN AMRO BANK NETHERLAND',
  'VAKIFBANK ETIMESGUT SUBESI',
  'YAPI KREDI BANK',
  'Dallah Albaraka',
  'Bank of America',
  'M&T Bank',
  'Key Bank',
  'Wells Fargo',
  'Raiffeisenbank Graz-Straßgang',
  'Raiffeisenbank Schilcherland',
  'Bank Burgenland',
  'Volksbank Graz-Straßgang',
  'Bank99 AG Austria',
  'Vivid Money S.A.',
  'First Bank of Nigeria',
  'BUNQ B.V.',
  'Xapo Bank Limited',
  'BANCO MULTIPLE BHD',
  'BANRESERVAS BANK',
  'Truist Bank',
  'Westpac Banking Corporation'
]

const GERMANY = [
  "Basler Kantonalbank",
  "BayernLB, Munich",
  "Bremer Bank, Bremen",
  "Commerzbank, Frankfurt",
  "Comdirect Bank",
  "Consorsbank, Nuremberg",
  "DAB BNP Paribas, Munich",
  "DekaBank (Germany), Frankfurt",
  "Deutsche Bank, Frankfurt",
  "Deutsche Pfandbriefbank, Garching bei München (next to Munich)",
  "Die Steiermärkische Bank und Sparkassen AG",
  "DZ Bank, Frankfurt",
  "GLS Bank, Bochum",
  "Hamburg Commercial Bank (formerly HSH Nordbank), Hamburg",
  "HypoVereinsbank",
  "KfW, Frankfurt",
  "Landesbank Baden-Württemberg, Stuttgart",
  "Landesbank Berlin Holding, Berlin",
  "Landesbank Hessen-Thüringen, Frankfurt",
  "Landwirtschaftliche Rentenbank",
  "Migros Bank",
  "National-Bank AG, Essen",
  "FIDOR bank AG",
  "N26, Berlin",
  "Nord/LB, Hanover",
  "NRW.Bank, Düsseldorf",
  "Portigon Financial Services, Düsseldorf",
  "SolarisBank, Berlin",
  "Sparkasse Essen",
  "Swissquote Bank",
  "Wirecard Bank"
]

const AUSTRIA = [
  "Addiko Bank",
  "Allianz Investmentbank",
  "AlpenBank",
  "American Express",
  "Anglo Irish Bank",
  "Austrian AAB Bank AG",
  "Austrian Anadi Bank AG",
  "Autobank AG",
  "Banco do Brasil",
  "Bank Austria Wohnbaubank AG",
  "Bank für Tirol und Vorarlberg",
  "Bank Gutmann",
  "Bank of China",
  "Bank Winter & Co",
  "Bankhaus Denzel AG",
  "Bankhaus Carl Spängler & Co",
  "Bankhaus Krentschker & Co",
  "Bankhaus Schelhammer & Schattera",
  "Bausparkasse der österreichischen Sparkassen",
  "Bausparkasse Wüstenrot",
  "BAWAG P.S.K.",
  "BAWAG P.S.K. Wohnbaubank",
  "BMW Austria Bank",
  "BKS Bank",
  "BNP Paribas",
  "BNP Paribas SA",
  "Brüll Kallmus Bank",
  "Capital Bank International-GRAWE Group",
  "card complete Service Bank",
  "Citibank Europe",
  "Commerzbank (Schweiz) Private Banking",
  "Commerzialbank Mattersburg im Burgenland",
  "Credit Suisse (Luxembourg)",
  "Deniz Bank",
  "Deutsche Bank AG Filiale Wien",
  "DolomitenBank Osttirol-Westkärnten",
  "easybank",
  "Erste Bank der Österreichischen Sparkassen",
  "Erste Bank",
  "European American Investment Bank",
  "FCA Bank",
  "FIL Fondsbank GmbH (Wien)",
  "flatex Bank",
  "Ford Bank Austria",
  "Generali Bank",
  "Hellobank BNP Paribas Austria",
  "HYPO NOE Landesbank für Niederösterreich und Wien",
  "HYPO TIROL BANK",
  "Hypo Vorarlberg Bank",
  "HYPO-BANK BURGENLAND",
  "ICBC Austria Bank",
  "IMMO-BANK AG",
  "ING-DiBa Austria",
  "JPMorgan Chase",
  "Kathrein & Co. Privatgeschäftsbank",
  "Kommunalkredit Austria",
  "Landes-Hypothekenbank Steiermark",
  "LEASFINANZ Bank",
  "LGT Bank",
  "Liechtensteinische Landesbank (Österreich)",
  "Marchfelder Bank eG",
  "Mercedez-Benz Bank GmbH",
  "Mizuho Bank Europe NV",
  "MUFG Bank (Europe)",
  "NOTARREUHANDBANK AG",
  "Oberbank",
  "Oberösterreichische Landesbank AG",
  "Österreichische Entwicklungsbank AG",
  "Österreichische Kontrollbank",
  "Österreichische Nationalbank",
  "Österreichische Ärzte- und Apothekerbank",
  "Österreichische Verkehrskreditbank",
  "PARTNER BANK",
  "paybox Bank",
  "Porsche Bank",
  "Posojilnica Bank eGen",
  "PSA Bank Österreich",
  "Raiffeisen Bank International",
  "Raiffeisen Bausparkasse",
  "Raiffeisen Centrobank AG",
  "Raiffeisen Wohnbaubank AG",
  "Raiffeisen-Landesbank Steiermark AG",
  "Raiffeisen Landesbank Tirol",
  "Raiffeisenlandesbank Burgenland und Revisionsverband eGen",
  "Raiffeisenlandesbank Kärnten",
  "Raiffeisenlandesbank Niederösterreich-Wien",
  "Raiffeisenlandesbank Oberösterreich",
  "Raiffeisenlandesbank Vorarlberg",
  "Raiffeisenverband Salzburg eGen",
  "RCI Banque SA",
  "s Wohnbaubank AG",
  "SALZBURGER LANDES-HYPOTHEKENBANK AG",
  "Santander Consumer",
  "Bank GmbH",
  "Sberbank Europe Group",
  "Schoellerbank",
  "Société Générale Zweigniederlassung Wien",
  "SPAR-FINANZ BANK AG",
  "Standard Chartered Bank (Wien)",
  "start:bausparkasse AG",
  "State Street Bank International GmbH Filiale Wien",
  "Steyler Bank GmbH",
  "Svenska Handelsbanken",
  "TeamBank Österreich",
  "UBS Europe SE (Österreich)",
  "UniCredit Bank AG (Wien)",
  "UniCredit CAIB",
  "Vakifbank International",
  "Volksbank Kärnten eG",
  "Volksbank Niederösterreich AG",
  "Volksbank Oberösterreich AG",
  "Volksbank Raiffeisenbank Oberbayern Südost eG (Salzburg)",
  "Volksbank Salzburg eG",
  "Volksbank Steiermark AG",
  "Volksbank Tirol AG",
  "VOLKSBANK VORARLBERG e. Gen.",
  "VOLKSBANK WIEN AG",
  "Volkskreditbank",
  "VTB Bank (Europe) SE (Wien)",
  "Walser Privatbank AG",
  "Western Union International Bank",
  "Wiener Privatbank Immobilieninvest",
  "WISE",
  "WSK Bank AG",
  "Zürcher Kantonalbank Österreich AG"
]

const SWITZERLAND = [
  "Julius Baer Group",
  "Vontobel",
  "Pictet Group",
  "Lombard Odier",
  "J. Safra Sarasin",
  "Union Bancaire Privee",
  "EFG International",
  "Zurich Cantonal Bank",
  "Banque cantonale vaudoise",
  "Raiffeisen",
  "PostFinance",
  "Banque Heritage",
  "Bank SYZ",
  "Bordier & Cie",
  "Compagnie Bancaire Helvétique",
  "Edmond de Rothschild Group",
  "Geneva Swiss Bank",
  "Gonet & Cie",
  "E. Gutzwiller & Cie, Banquiers",
  "Habib Bank AG Zurich",
  "Landolt & Cie",
  "Lienhardt & Partner Privatbank Zürich",
  "Reichmuth & Co",
  "REYL Group",
  "Union Bancaire Privée",
  "Rahn+Bodmer Co.",
  "Vontobel"
]

const ISRAEL = [
  "Bank Hapoalim",
  "Bank Leumi",
  "Bank Mizrahi-Tefahot",
  "First International Bank of Israel",
  "Israel Discount Bank"
]

const GLOBAL = [...GERMANY, ...AUSTRIA, ...ISRAEL, ...SWITZERLAND, ...GENERAL];

const USD = _.uniq([...UNITED_SATES, ...GLOBAL]);

const EUR = _.uniq([...UNITED_KINGDOM, ...GLOBAL]);

const GBP = EUR;

export const Banks = { USD, EUR, GBP }
