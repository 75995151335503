import getSymbolFromCurrency from 'currency-symbol-map';
import { Banks } from './Banks';


const Enums = {
    APP_NAME: "Eccount Money",
    BASE_URL: 'https://eccount-backend.onrender.com',//'https://eccountapi-6eq35705.b4a.run', /*'https://eccountserver-k4ug9muo.b4a.run', 'https://eccountapi-rcompston.b4a.run',*///'http://localhost:3001',
    CURRENCY_API: 'http://api.currencylayer.com/live?access_key=b4bf728df45414e6bee9884c707540aa&format=1',
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    URL_REGEX: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
    PIN_REGEX: /^\d{4}$/,
    BANKS: Banks,
    COLORS: {
        white: " #fff",
        orange: "#D3006C",
        yellow: "#f5ca04",
        grey_500: "#333",
        grey_400: "#555"
    },
    ACCOUNT_TYPES: {
        AH: 'ACCOUNT_HOLDER',
        AO: 'ACCOUNT_OFFICER',
        ROOT: 'ROOT',
    },
    STORAGE_KEYS: {
        TOKEN: 'TOKEN',
        USER: 'USER',
        ACTION: 'ACTION',
    },
    CURRENCIES: [
        { name: 'Euro', code: 'EUR', symbol: getSymbolFromCurrency('EUR') },
        {
            name: 'Pounds Sterling',
            code: 'GBP',
            symbol: getSymbolFromCurrency('GBP'),
        },
        { name: 'US Dollar', code: 'USD', symbol: getSymbolFromCurrency('USD') },
    ],
    PERMISSIONS: {
        ADMIN_ACCOUNT_MANAGER: "ADMIN_ACCOUNT_MANAGER",
        CELL_MANAGER: "CELL_MANAGER",
        ZONE_MANEGER: "ZONE_MANEGER",
        PUBLISHER: "PUBLISHER",
        EDITOR: "EDITOR",
        MUSIC_MANAGER: "MUSIC_MANAGER",
        BACK_OFFICE_ACCESS: "BACK_OFFICE_ACCESS",
    },
    BROADCAST_CHANNELS: {
        ACCOUNTS: "ACCOUNTS"
    },
    SOLARIZED: {
        text: {
            primary: "#333",
            secondary: "#2688c9",
        },
        background: {
            default: "#FFF0",
        },
        context: {
            background: "#cb4b16",
            text: "#FFFFFF",
        },
        divider: {
            default: "#d7d7d7",
        },

        action: {
            button: "rgba(0,0,0,.54)",
            hover: "rgba(0,0,0,.08)",
            disabled: "rgba(0,0,0,.12)",
        },
    }
}
export default Enums